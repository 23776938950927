import { useCallback } from "react";
import { Service } from "../../../invoice.model";

import { InvoiceFormContext, useInvoiceForm } from "../InvoiceForm";
import { DescriptionInputContext, descriptionInputHasChanged, newDescriptionInputContext, validateDescriptionInput } from "./DescriptionInput";
import { DurationInputContext, durationInputHasChanged, newDurationInputContext, validateDurationInput } from "./DurationInput";
import { newToolsInputContext, ToolsInputContext, toolsInputHasChanged, validateToolsInput } from "./ToolsInput";

export const useServiceInput = (index: number) => {
    const { form, setForm } = useInvoiceForm();

    const serviceInput = form.serviceInputs[index];

    const setServiceInput = useCallback((reducer: (serviceInput: ServiceInputContext) => ServiceInputContext) => {
        setForm((previousState): InvoiceFormContext => {
            const serviceInput = reducer(previousState.serviceInputs[index]);

            const serviceInputs = [...previousState.serviceInputs];
            serviceInputs.splice(index, 1, serviceInput);

            return { ...previousState, serviceInputs };
        });
    }, [setForm, index]);

    return { serviceInput, setServiceInput };
};

export interface ServiceInputContext {
    descriptionInput: DescriptionInputContext;
    durationInput: DurationInputContext;
    toolsInput: ToolsInputContext;
}

export const newServiceInputContext = (service: Service): ServiceInputContext => ({
    descriptionInput: newDescriptionInputContext(service),
    durationInput: newDurationInputContext(service),
    toolsInput: newToolsInputContext(service),
});

export const serviceInputsHaveChanged = (form: InvoiceFormContext) => {
    if ( form.invoice.services.length !== form.serviceInputs.length ) return true;

    return form.serviceInputs.some((_, index) => serviceInputHasChanged(form, index));
}

const serviceInputHasChanged = (form: InvoiceFormContext, index: number) => {
    return descriptionInputHasChanged(form, index) || durationInputHasChanged(form, index) || toolsInputHasChanged(form, index);
};

export const validateServiceInput = (form: InvoiceFormContext, index: number) => {
    return validateDescriptionInput(form, index) || validateDurationInput(form, index) || validateToolsInput(form, index);
};
