import React, { createContext, PropsWithChildren, useCallback, useContext, useMemo } from "react";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";

import moment from "moment";

import { LinearProgress } from "./components/LinearProgress";
import { skipAccordingToHoursAndMinutesSkippingWeekendAsWell } from "./date-utility";
import { TaskDocument } from "./task/task.model";
import { useUser } from "./user/UserProvider";
import { useAdhocCustomerTasks, useAdhocGardenerTasks, useComplaintTasks, useEvaluateGardenerTasks, useGardenerInformationReviewTasks, useOnboardingTasks } from "./workflow/follow-up/appointment/TaskProvider";
import { useManualPaymentCollectionTasks } from "./finance/billing/BillingTaskProvider";

interface LinkValue {
    insightLinks: JSX.Element;
    workflowLinks: JSX.Element;
    taskCount: string;
    logOutLink: JSX.Element;
}

const LinkContext = createContext<LinkValue | undefined>(undefined);

export const useIsSuperAdministrator = () => {
    const administrator = useUser();
    return administrator.id === "7uAgJGqVUQaTHVSBouZjkurwHWt2" || administrator.id === "soITz24cibasdoaoGuPd328ismF3";
}

export const LinkProvider = (props: PropsWithChildren<{}>) => {
    const history = useHistory();
    const logOut = useCallback((event: React.MouseEvent) => {
        event.preventDefault();

        history.replace("/logout")
    }, [history]);

    const now = moment().toISOString(true);

    const evaluateGardenerTasks = useEvaluateGardenerTasks();
    const currentEvaluateGardenerTasks = useMemo(() => {
        return evaluateGardenerTasks.filter(task => !task.specialPartnership && task.performAfterDate <= now);
    }, [evaluateGardenerTasks, now]);

    const customerAdhocTasks = useAdhocCustomerTasks();
    const gardenerAdhocTasks = useAdhocGardenerTasks();

    const currentAdhocTasks = useMemo(() => {
        return [...customerAdhocTasks, ...gardenerAdhocTasks].filter(task => task.performAfterDate <= now);
    }, [customerAdhocTasks, gardenerAdhocTasks, now]);

    const complaintTasks = useComplaintTasks();
    const relevantComplaintTasks = useMemo(() => {
        return complaintTasks
            .filter(x => x.complaint.status === "contacting customer" || x.complaint.status === "contacting gardener")
            .filter(task => task.performAfterDate <= now);
    }, [complaintTasks, now]);

    const currentComplaintTasks = useMemo(() => [...relevantComplaintTasks], [relevantComplaintTasks]);
    const manualPaymentCollectionTasks = useManualPaymentCollectionTasks();
    const currentPaymentCollectionTasks = useMemo(() => {
        return manualPaymentCollectionTasks.filter(task => skipAccordingToHoursAndMinutesSkippingWeekendAsWell(task.performAfterDate, { hour: 8, minute: 0 }, { hour: 20, minute: 0 }) <= now);
    }, [manualPaymentCollectionTasks, now]);

    const onboardingTasks = useOnboardingTasks();
    const currentOnboardingTasks = useMemo(() => {
        const applicationTasks = onboardingTasks.filter(task => task.application.status === "accepted" && task.performAfterDate <= now);
        const interviewTasks = onboardingTasks.filter(task => task.application.status === "checked" && task.performAfterDate <= now);

        return [...applicationTasks, ...interviewTasks];
    }, [onboardingTasks, now]);

    const gardenerInformationReviewTasks = useGardenerInformationReviewTasks();
    const currentGardenerInformationReviewTasks = useMemo(() => {
        return gardenerInformationReviewTasks.filter(task => task.performAfterDate <= now);
    }, [gardenerInformationReviewTasks, now]);

    const value = useMemo(() => {

        const insightLinks = (
            <>
                <NavLink to="/dashboard">Dashboard</NavLink>
                <NavLink to="/customers">Kunder</NavLink>
                <NavLink to="/gardeners">Havemænd</NavLink>
                <NavLink to="/invoices">Fakturaer</NavLink>
            </>
        );

        const workflowLinks = (
            <>
                {<NavLink to="/evaluate-gardener">{formatTitle("Evaluer samarbejde", currentEvaluateGardenerTasks.length)}</NavLink>}
                {<NavLink to="/workflow/adhoc">{formatTitle("Diverse", currentAdhocTasks.length)}</NavLink>}
                {<NavLink to="/complaint">{formatTitle("Reklamation", currentComplaintTasks.length)}</NavLink>}
                {<NavLink to="/finance/payment-collection">{formatTitle("Opkrævning", currentPaymentCollectionTasks.length)}</NavLink>}
                {<NavLink to="/onboarding">{formatTitle("Onboarding", currentOnboardingTasks.length)}</NavLink>}
                {<NavLink to="/check-gardener-information">{formatTitle("Tjek stamdata", currentGardenerInformationReviewTasks.length)}</NavLink>}
            </>
        );

        const taskCount = formatTaskCount([...currentEvaluateGardenerTasks, ...currentComplaintTasks, ...currentAdhocTasks, ...currentPaymentCollectionTasks, ...currentOnboardingTasks, ...currentGardenerInformationReviewTasks]);

        const logOutLink = (
            <a href="/" onClick={logOut}>Log ud</a>
        );

        return { insightLinks, workflowLinks, taskCount, logOutLink };
    }, [logOut, currentEvaluateGardenerTasks, currentComplaintTasks, currentAdhocTasks, currentPaymentCollectionTasks, currentOnboardingTasks, currentGardenerInformationReviewTasks]);

    if ( value === undefined ) return <LinearProgress />;

    return (
        <LinkContext.Provider value={value}>
            {props.children}
        </LinkContext.Provider>
    );
};

export const useLinks = () => {
    return useContext(LinkContext) as LinkValue;
};

function formatTitle(text: string, count: number): string {
    if ( count === 0 ) return text;

    return `${text} (${count})`;
};

function formatTaskCount(tasks: TaskDocument[]): string {
    if ( tasks.length === 0 ) return "0";

    const sortedTasks = tasks.sort((a, b) => {
        if ( a.performAfterDate < b.performAfterDate ) return -1;
        if ( a.performAfterDate > b.performAfterDate ) return 1;
        return 0;
    });

    const counters: number[] = [0];
    let lastAnchor = sortedTasks[0].performAfterDate;

    sortedTasks.forEach(task => {
        const nextAnchor = task.performAfterDate;

        if ( moment(lastAnchor).isSame(nextAnchor, "day") ) {
            counters.push((counters.pop() ?? 0) + 1);
        } else {
            lastAnchor = nextAnchor;
            counters.push(1);
        }
    });

    counters.push(counters.pop() ?? 0);

    return counters.join(" + ");
}
