import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";

import moment from "moment";

import { TaskDocument } from "../../task/task.model";
import { useOnboardingTasks } from "../../workflow/follow-up/appointment/TaskProvider";
import { ApplicationTab } from "./ApplicationTab";

export const IntegratedApplicationTab = () => {
    const history = useHistory();
    const goToTask = useCallback((task: TaskDocument) => {
        history.push(`/onboarding/application/${task.id}`);
    }, [history]);

    const now = moment().toISOString(true);

    const tasks = useOnboardingTasks();
    const relevantTasks = useMemo(() => tasks.filter(task => task.application.status === "accepted"), [tasks]);

    const currentTasks = useMemo(() => relevantTasks.filter(task => task.performAfterDate <= now), [relevantTasks, now]);
    const futureTasks = useMemo(() => relevantTasks.filter(task => task.performAfterDate > now), [relevantTasks, now]);

    return (
        <ApplicationTab
            currentTasks={currentTasks}
            futureTasks={futureTasks}
            goToTask={goToTask}
        />
    );
};
